import React, {useEffect} from 'react';
import './App.css';
import {AuthProvider} from "oidc-react";
import {
    Routes,
    Route,
    useNavigate, useLocation,
} from "react-router-dom";
import './App.css';
import {DatevFormatPage} from "./pages/DatevFormatPage/DatevFormatPage";

const LAST_PATH_KEY = "lastPath"

function App() {
    const navigate = useNavigate();
    const location = useLocation()
    let lastUrlStorage = localStorage.getItem(LAST_PATH_KEY)
    if (lastUrlStorage === undefined || lastUrlStorage === null) {
        lastUrlStorage = "/"
    }
    console.group("Proces");
    console.log(process.env);
    console.groupEnd();

    useEffect(
        () => {
            if (location.pathname !== "/auth/callback") {
                localStorage.setItem(LAST_PATH_KEY, location.pathname);
            }
        }
        , [location.pathname]
    )


    return (
        <div className="App">
            <AuthProvider
                autoSignIn={true}
                responseType="code"
                automaticSilentRenew={true}
                authority={process.env.REACT_APP_OIDC_AUTHORITY || "https://preprod-myaccount.agicap.cloud"}
                redirectUri={process.env.REACT_APP_OIDC_REDIRECT_URI || "http://localhost:3000/auth/callback"}
                popupRedirectUri={process.env.REACT_APP_OIDC_REDIRECT_URI || "http://localhost:3000/auth/callback"}
                silentRedirectUri={process.env.REACT_APP_OIDC_REDIRECT_URI || "http://localhost:3000/auth/callback"}
                clientId={process.env.REACT_APP_OIDC_CLIENT_ID || "agisight"}
                scope={process.env.REACT_APP_OIDC_SCOPE || "agicap:app openid profile"}
                onSignIn={(user) => {
                    // @ts-ignore
                    navigate(lastUrlStorage)
                }}
            >
                <Routes>
                    <Route path="*" element={<DatevFormatPage/>}/>
                </Routes>
            </AuthProvider>
        </div>
    );
}

export default App;
